document.addEventListener('DOMContentLoaded', function() {
    // Function to get all query parameters as an object
    function getAllQueryParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {};
        for (const [key, value] of urlParams.entries()) {
            params[key] = value;
        }
        return params;
    }

    // Function to get a cookie value by name
    function getCookie(cname) {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // Get all query parameters
    const queryParams = getAllQueryParams();

    // List of UTM and custom parameters to handle
    const utmParams = [
        'lead_attribution', 'utm_source', 'utm_campaign', 'utm_medium',
        'utm_content', 'utm_term', 'campaign_id', 'adgroup_id', 'creative_id',
        'keyword_id', 'extension_id', 'target_id', 'matchtype', 'ad_network',
        'ad_position', 'ad_device', 'ad_devicemodel', 'ad_physical_location',
        'gclid'
    ];

    // Loop through the list of parameters and update the form input fields accordingly
    utmParams.forEach(function(param) {
        if (queryParams[param]) {
            const inputField = document.querySelector(`input[data-variable="${param}"]`);
            if (inputField) {
                inputField.value = queryParams[param];
            }
        }
    });

    // Handle sub_affiliate_* dynamic keys
    Object.keys(queryParams).forEach(function(param) {
        if (param.startsWith('sub_affiliate_')) {
            const inputField = document.querySelector(`input[data-variable="${param}"]`);
            if (inputField && queryParams[param]) { // Ensure that the value is not empty
                inputField.value = queryParams[param];
            }
        }
    });

    // Get the Mixpanel ID from the cookie and set it in the micro_user_id input field
    const mixpanelId = getCookie('mixp_id');
    if (mixpanelId) {
        const microUserIdInput = document.querySelector('input[data-variable="micro_user_id"]');
        if (microUserIdInput) {
            microUserIdInput.value = mixpanelId;
        }
    }
});
